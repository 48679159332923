/* eslint-disable camelcase */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/homeleft',
    name: 'HomeViewLeft',
    component: () => import('../views/HomeViewLeft.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../views/Agreement.vue'),
    meta: {
      title: '万能简历_服务条款',
    },
  },
  {
    path: '/resiger',
    name: 'ResigerView',
    component: () => import('../views/ResigerView.vue'),
    meta: {
      title: '万能简历_注册',
    },
  },
  /*   {
    path: '/ceshibak',
    name: 'Ceshibak',
    component: () => import('@/views/CeShibak.vue'),
    children: [
      {
        path: '/bioModule-162203',
        name: 'bioModule162203',
        component: () => import('@/components/BioModuleEdits/BioModule162203.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162205',
        name: 'bioModule162205',
        component: () => import('@/components/BioModuleEdits/BioModule162205.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162206',
        name: 'bioModule162206',
        component: () => import('@/components/BioModuleEdits/BioModule162206.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162214',
        name: 'bioModule162214',
        component: () => import('@/components/BioModuleEdits/BioModule162214.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162215',
        name: 'bioModule162215',
        component: () => import('@/components/BioModuleEdits/BioModule162215.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162216',
        name: 'bioModule162216',
        component: () => import('@/components/BioModuleEdits/BioModule162216.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162222',
        name: 'bioModule162222',
        component: () => import('@/components/BioModuleEdits/BioModule162222.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162223',
        name: 'bioModule162223',
        component: () => import('@/components/BioModuleEdits/BioModule162223.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162228',
        name: 'bioModule162228',
        component: () => import('@/components/BioModuleEdits/BioModule162228.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162229',
        name: 'bioModule162229',
        component: () => import('@/components/BioModuleEdits/BioModule162229.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162235',
        name: 'bioModule162235',
        component: () => import('@/components/BioModuleEdits/BioModule162235.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162236',
        name: 'bioModule162236',
        component: () => import('@/components/BioModuleEdits/BioModule162236.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162238',
        name: 'bioModule162238',
        component: () => import('@/components/BioModuleEdits/BioModule162238.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162240',
        name: 'bioModule162240',
        component: () => import('@/components/BioModuleEdits/BioModule162240.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162241',
        name: 'bioModule162241',
        component: () => import('@/components/BioModuleEdits/BioModule162241.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162242',
        name: 'bioModule162242',
        component: () => import('@/components/BioModuleEdits/BioModule162242.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162252',
        name: 'bioModule162252',
        component: () => import('@/components/BioModuleEdits/BioModule162252.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162253',
        name: 'bioModule162253',
        component: () => import('@/components/BioModuleEdits/BioModule162253.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162254',
        name: 'bioModule162254',
        component: () => import('@/components/BioModuleEdits/BioModule162254.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162262',
        name: 'bioModule162262',
        component: () => import('@/components/BioModuleEdits/BioModule162262.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
    ],
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  }, */
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('@/views/BioEdit.vue'),
    children: [
      {
        path: '/bioModule-162203',
        name: 'bioModule162203',
        component: () => import('@/components/BioModuleEdits/BioModule162203.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162205',
        name: 'bioModule162205',
        component: () => import('@/components/BioModuleEdits/BioModule162205.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162206',
        name: 'bioModule162206',
        component: () => import('@/components/BioModuleEdits/BioModule162206.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162214',
        name: 'bioModule162214',
        component: () => import('@/components/BioModuleEdits/BioModule162214.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162215',
        name: 'bioModule162215',
        component: () => import('@/components/BioModuleEdits/BioModule162215.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162216',
        name: 'bioModule162216',
        component: () => import('@/components/BioModuleEdits/BioModule162216.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162222',
        name: 'bioModule162222',
        component: () => import('@/components/BioModuleEdits/BioModule162222.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162223',
        name: 'bioModule162223',
        component: () => import('@/components/BioModuleEdits/BioModule162223.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162228',
        name: 'bioModule162228',
        component: () => import('@/components/BioModuleEdits/BioModule162228.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162229',
        name: 'bioModule162229',
        component: () => import('@/components/BioModuleEdits/BioModule162229.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162235',
        name: 'bioModule162235',
        component: () => import('@/components/BioModuleEdits/BioModule162235.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162236',
        name: 'bioModule162236',
        component: () => import('@/components/BioModuleEdits/BioModule162236.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162238',
        name: 'bioModule162238',
        component: () => import('@/components/BioModuleEdits/BioModule162238.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162240',
        name: 'bioModule162240',
        component: () => import('@/components/BioModuleEdits/BioModule162240.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162241',
        name: 'bioModule162241',
        component: () => import('@/components/BioModuleEdits/BioModule162241.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162242',
        name: 'bioModule162242',
        component: () => import('@/components/BioModuleEdits/BioModule162242.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162252',
        name: 'bioModule162252',
        component: () => import('@/components/BioModuleEdits/BioModule162252.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162253',
        name: 'bioModule162253',
        component: () => import('@/components/BioModuleEdits/BioModule162253.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162254',
        name: 'bioModule162254',
        component: () => import('@/components/BioModuleEdits/BioModule162254.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/bioModule-162262',
        name: 'bioModule162262',
        component: () => import('@/components/BioModuleEdits/BioModule162262.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
    ],
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/views/ResumeMarketView.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  /*   {
    path: '/edit',
    name: 'edit',
    component: () => import('../views/EditBioView.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  }, */
  {
    path: '/onlinebio',
    name: 'OnlineBio',
    component: () => import('../views/OnlineBio.vue'),
    children: [
      {
        path: '/resumes',
        name: 'BioModules',
        component: () => import('../views/BioModules.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
    ],
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/myuser',
    name: 'UserView',
    component: () => import('../views/UserView.vue'),
    children: [
      {
        path: '/myuserinfo',
        name: 'UserInfoView',
        component: () => import('../views/UserInfoView.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/myuserorder',
        name: 'UserOrderView',
        component: () => import('../views/UserOrderView.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/myuservip',
        name: 'UserVipView',
        component: () => import('../views/UserVipView.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/myuservipc',
        name: 'UserVipCView',
        component: () => import('../views/UserVipViewC.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/myuserpromote',
        name: 'UserPromoteView',
        component: () => import('../views/UserPromoteView.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
      {
        path: '/myuserbind',
        name: 'UserBindView',
        component: () => import('../views/UserBindView.vue'),
        meta: {
          title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
        },
      },
    ],
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/pay/:id',
    name: 'UserPayView',
    component: () => import('@/views/UserPayView.vue'),
    params: {
      // 可以定义默认值
      id: { type: Number, required: true },
    },
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162200',
    name: 'detail_162200',
    component: () => import('@/views/detail/detail_162200.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162201',
    name: 'detail_162201',
    component: () => import('@/views/detail/detail_162201.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162202',
    name: 'detail_162202',
    component: () => import('@/views/detail/detail_162202.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162203',
    name: 'detail_162203',
    component: () => import('@/views/detail/detail_162203.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162204',
    name: 'detail_162204',
    component: () => import('@/views/detail/detail_162204.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162205',
    name: 'detail_162205',
    component: () => import('@/views/detail/detail_162205.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162206',
    name: 'detail_162206',
    component: () => import('@/views/detail/detail_162206.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162207',
    name: 'detail_162207',
    component: () => import('@/views/detail/detail_162207.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162208',
    name: 'detail_162208',
    component: () => import('@/views/detail/detail_162208.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162209',
    name: 'detail_162209',
    component: () => import('@/views/detail/detail_162209.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162210',
    name: 'detail_1622010',
    component: () => import('@/views/detail/detail_162210.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162211',
    name: 'detail_162211',
    component: () => import('@/views/detail/detail_162211.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162212',
    name: 'detail_162212',
    component: () => import('@/views/detail/detail_162212.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162213',
    name: 'detail_162213',
    component: () => import('@/views/detail/detail_162213.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162214',
    name: 'detail_162214',
    component: () => import('@/views/detail/detail_162214.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162215',
    name: 'detail_162215',
    component: () => import('@/views/detail/detail_162215.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162216',
    name: 'detail_162216',
    component: () => import('@/views/detail/detail_162216.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162217',
    name: 'detail_162217',
    component: () => import('@/views/detail/detail_162217.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162218',
    name: 'detail_162218',
    component: () => import('@/views/detail/detail_162218.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162219',
    name: 'detail_162219',
    component: () => import('@/views/detail/detail_162219.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162220',
    name: 'detail_162220',
    component: () => import('@/views/detail/detail_162220.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162221',
    name: 'detail_162221',
    component: () => import('@/views/detail/detail_162221.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162222',
    name: 'detail_162222',
    component: () => import('@/views/detail/detail_162222.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162223',
    name: 'detail_162223',
    component: () => import('@/views/detail/detail_162223.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162224',
    name: 'detail_162224',
    component: () => import('@/views/detail/detail_162224.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162225',
    name: 'detail_162225',
    component: () => import('@/views/detail/detail_162225.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162226',
    name: 'detail_162226',
    component: () => import('@/views/detail/detail_162226.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162227',
    name: 'detail_162227',
    component: () => import('@/views/detail/detail_162227.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162228',
    name: 'detail_162228',
    component: () => import('@/views/detail/detail_162228.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162229',
    name: 'detail_162229',
    component: () => import('@/views/detail/detail_162229.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162230',
    name: 'detail_162230',
    component: () => import('@/views/detail/detail_162230.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162231',
    name: 'detail_162231',
    component: () => import('@/views/detail/detail_162231.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162232',
    name: 'detail_162232',
    component: () => import('@/views/detail/detail_162232.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162233',
    name: 'detail_162233',
    component: () => import('@/views/detail/detail_162233.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162234',
    name: 'detail_162234',
    component: () => import('@/views/detail/detail_162234.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162235',
    name: 'detail_162235',
    component: () => import('@/views/detail/detail_162235.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162236',
    name: 'detail_162236',
    component: () => import('@/views/detail/detail_162236.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162237',
    name: 'detail_162237',
    component: () => import('@/views/detail/detail_162237.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162238',
    name: 'detail_162238',
    component: () => import('@/views/detail/detail_162238.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },

  {
    path: '/detail-162239',
    name: 'detail_162239',
    component: () => import('@/views/detail/detail_162239.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162240',
    name: 'detail_162240',
    component: () => import('@/views/detail/detail_162240.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162241',
    name: 'detail_162241',
    component: () => import('@/views/detail/detail_162241.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162242',
    name: 'detail_162242',
    component: () => import('@/views/detail/detail_162242.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162243',
    name: 'detail_162243',
    component: () => import('@/views/detail/detail_162243.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162244',
    name: 'detail_162244',
    component: () => import('@/views/detail/detail_162244.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162245',
    name: 'detail_162245',
    component: () => import('@/views/detail/detail_162245.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162246',
    name: 'detail_162246',
    component: () => import('@/views/detail/detail_162246.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162247',
    name: 'detail_162247',
    component: () => import('@/views/detail/detail_162247.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162248',
    name: 'detail_162248',
    component: () => import('@/views/detail/detail_162248.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162249',
    name: 'detail_162249',
    component: () => import('@/views/detail/detail_162249.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162250',
    name: 'detail_162250',
    component: () => import('@/views/detail/detail_162250.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162251',
    name: 'detail_162251',
    component: () => import('@/views/detail/detail_162251.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162252',
    name: 'detail_162252',
    component: () => import('@/views/detail/detail_162252.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162253',
    name: 'detail_162253',
    component: () => import('@/views/detail/detail_162253.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162254',
    name: 'detail_162254',
    component: () => import('@/views/detail/detail_162254.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162255',
    name: 'detail_162255',
    component: () => import('@/views/detail/detail_162255.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162256',
    name: 'detail_162256',
    component: () => import('@/views/detail/detail_162256.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162257',
    name: 'detail_162257',
    component: () => import('@/views/detail/detail_162257.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162258',
    name: 'detail_162258',
    component: () => import('@/views/detail/detail_162258.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162259',
    name: 'detail_162259',
    component: () => import('@/views/detail/detail_162259.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162260',
    name: 'detail_162260',
    component: () => import('@/views/detail/detail_162260.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162261',
    name: 'detail_162261',
    component: () => import('@/views/detail/detail_162261.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162262',
    name: 'detail_162262',
    component: () => import('@/views/detail/detail_162262.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162263',
    name: 'detail_162263',
    component: () => import('@/views/detail/detail_162263.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162264',
    name: 'detail_1622064',
    component: () => import('@/views/detail/detail_162264.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162265',
    name: 'detail_162265',
    component: () => import('@/views/detail/detail_162265.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162266',
    name: 'detail_162266',
    component: () => import('@/views/detail/detail_162266.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162267',
    name: 'detail_162267',
    component: () => import('@/views/detail/detail_162267.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162268',
    name: 'detail_162268',
    component: () => import('@/views/detail/detail_162268.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162269',
    name: 'detail_162269',
    component: () => import('@/views/detail/detail_162269.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162270',
    name: 'detail_162270',
    component: () => import('@/views/detail/detail_162270.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162271',
    name: 'detail_162271',
    component: () => import('@/views/detail/detail_162271.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162272',
    name: 'detail_162272',
    component: () => import('@/views/detail/detail_162272.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162273',
    name: 'detail_162273',
    component: () => import('@/views/detail/detail_162273.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162274',
    name: 'detail_162274',
    component: () => import('@/views/detail/detail_162274.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162275',
    name: 'detail_162275',
    component: () => import('@/views/detail/detail_162275.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162276',
    name: 'detail_162276',
    component: () => import('@/views/detail/detail_162276.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162277',
    name: 'detail_162277',
    component: () => import('@/views/detail/detail_162277.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162278',
    name: 'detail_1622078',
    component: () => import('@/views/detail/detail_162278.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162279',
    name: 'detail_162279',
    component: () => import('@/views/detail/detail_162279.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162280',
    name: 'detail_162280',
    component: () => import('@/views/detail/detail_162280.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162281',
    name: 'detail_162281',
    component: () => import('@/views/detail/detail_162281.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162282',
    name: 'detail_162282',
    component: () => import('@/views/detail/detail_162282.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162283',
    name: 'detail_162283',
    component: () => import('@/views/detail/detail_162283.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162284',
    name: 'detail_162284',
    component: () => import('@/views/detail/detail_162284.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162285',
    name: 'detail_162285',
    component: () => import('@/views/detail/detail_162285.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162286',
    name: 'detail_162286',
    component: () => import('@/views/detail/detail_162286.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162287',
    name: 'detail_162287',
    component: () => import('@/views/detail/detail_162287.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162288',
    name: 'detail_162288',
    component: () => import('@/views/detail/detail_162288.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162289',
    name: 'detail_162289',
    component: () => import('@/views/detail/detail_162289.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162290',
    name: 'detail_162290',
    component: () => import('@/views/detail/detail_162290.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162291',
    name: 'detail_162291',
    component: () => import('@/views/detail/detail_162291.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162292',
    name: 'detail_162292',
    component: () => import('@/views/detail/detail_162292.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162293',
    name: 'detail_162293',
    component: () => import('@/views/detail/detail_162293.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162294',
    name: 'detail_162294',
    component: () => import('@/views/detail/detail_162294.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162295',
    name: 'detail_162295',
    component: () => import('@/views/detail/detail_162295.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162296',
    name: 'detail_162296',
    component: () => import('@/views/detail/detail_162296.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162297',
    name: 'detail_162297',
    component: () => import('@/views/detail/detail_162297.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162298',
    name: 'detail_162298',
    component: () => import('@/views/detail/detail_162298.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162299',
    name: 'detail_162299',
    component: () => import('@/views/detail/detail_162299.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162300',
    name: 'detail_162300',
    component: () => import('@/views/detail/detail_162300.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162302',
    name: 'detail_162302',
    component: () => import('@/views/detail/detail_162302.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162303',
    name: 'detail_162303',
    component: () => import('@/views/detail/detail_162303.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162304',
    name: 'detail_162304',
    component: () => import('@/views/detail/detail_162304.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162305',
    name: 'detail_162305',
    component: () => import('@/views/detail/detail_162305.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162306',
    name: 'detail_162306',
    component: () => import('@/views/detail/detail_162306.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162307',
    name: 'detail_162307',
    component: () => import('@/views/detail/detail_162307.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162308',
    name: 'detail_162308',
    component: () => import('@/views/detail/detail_162308.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162309',
    name: 'detail_162309',
    component: () => import('@/views/detail/detail_162309.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162310',
    name: 'detail_162310',
    component: () => import('@/views/detail/detail_162310.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162311',
    name: 'detail_162311',
    component: () => import('@/views/detail/detail_162311.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162312',
    name: 'detail_162312',
    component: () => import('@/views/detail/detail_162312.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162313',
    name: 'detail_162313',
    component: () => import('@/views/detail/detail_162313.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162314',
    name: 'detail_162314',
    component: () => import('@/views/detail/detail_162314.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162315',
    name: 'detail_162315',
    component: () => import('@/views/detail/detail_162315.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162316',
    name: 'detail_162316',
    component: () => import('@/views/detail/detail_162316.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162317',
    name: 'detail_162317',
    component: () => import('@/views/detail/detail_162317.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162318',
    name: 'detail_162318',
    component: () => import('@/views/detail/detail_162318.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162319',
    name: 'detail_162319',
    component: () => import('@/views/detail/detail_162319.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162320',
    name: 'detail_162320',
    component: () => import('@/views/detail/detail_162320.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162321',
    name: 'detail_162321',
    component: () => import('@/views/detail/detail_162321.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162322',
    name: 'detail_162322',
    component: () => import('@/views/detail/detail_162322.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162323',
    name: 'detail_162323',
    component: () => import('@/views/detail/detail_162323.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162324',
    name: 'detail_162324',
    component: () => import('@/views/detail/detail_162324.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162325',
    name: 'detail_162325',
    component: () => import('@/views/detail/detail_162325.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162326',
    name: 'detail_162326',
    component: () => import('@/views/detail/detail_162326.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162327',
    name: 'detail_162327',
    component: () => import('@/views/detail/detail_162327.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162328',
    name: 'detail_162328',
    component: () => import('@/views/detail/detail_162328.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162329',
    name: 'detail_162329',
    component: () => import('@/views/detail/detail_162329.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162330',
    name: 'detail_162330',
    component: () => import('@/views/detail/detail_162330.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162331',
    name: 'detail_162331',
    component: () => import('@/views/detail/detail_162331.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162332',
    name: 'detail_162332',
    component: () => import('@/views/detail/detail_162332.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162333',
    name: 'detail_162333',
    component: () => import('@/views/detail/detail_162333.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162334',
    name: 'detail_162334',
    component: () => import('@/views/detail/detail_162334.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162335',
    name: 'detail_162335',
    component: () => import('@/views/detail/detail_162335.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162336',
    name: 'detail_162336',
    component: () => import('@/views/detail/detail_162336.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162337',
    name: 'detail_162337',
    component: () => import('@/views/detail/detail_162337.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162338',
    name: 'detail_162338',
    component: () => import('@/views/detail/detail_162338.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162339',
    name: 'detail_162339',
    component: () => import('@/views/detail/detail_162339.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
  {
    path: '/detail-162340',
    name: 'detail_162340',
    component: () => import('@/views/detail/detail_162340.vue'),
    meta: {
      title: '万能简历_专业简历模板设计_在线生成简历神器_可编辑简历制作_免费',
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
