/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-mixed-operators */
// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { Loading } from 'element-ui';

let loading = { };
const htmlToPdf = {

  getPdf(title) {
    loading = Loading.service({
      lock: true,
      text: '正在下载pdf...',
      background: 'rgba(0,500,200,.5)',
    });
    html2Canvas(document.querySelector('#pdfDom'), {
      allowTaint: true,
      taintTest: false,
      useCORS: true,
      // y: 72, // 对Y轴进行裁切
      // width:1200,
      // height:5000,
      dpi: window.devicePixelRatio * 4, // 将分辨率提高到特定的DPI 提高四倍
      scale: 4, // 按比例增加分辨率
    }).then((canvas) => {
      loading.close();
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const pageHeight = (contentWidth / 592.28) * 841.89;
      let leftHeight = contentHeight;
      let position = 0;
      const imgWidth = 595.28;
      const imgHeight = (592.28 / contentWidth) * contentHeight;
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      const PDF = new JsPDF('', 'pt', 'a4');
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          if (leftHeight > 0) {
            PDF.addPage();
          }
        }
      }
      PDF.save(`${title}.pdf`);
    });
  },
};

export default htmlToPdf;
