// 本地缓存服务

const PRERIX = 'ginessential_';

// user 模块
const USER_PREFIX = `${PRERIX}user_`;
const USER_TOKEN = `${USER_PREFIX}token`;
const USER_INFO = `${USER_PREFIX}info`;
const USER_RESUMES = `${USER_PREFIX}resumes`;
const USER_RESUMES_LEN = `${USER_PREFIX}resumeslen`;
const BASE_INFO = `${USER_PREFIX}baseinfo`;

// 储存
const set = (key, data) => {
  localStorage.setItem(key, data);
};

// 读取
const get = (key) => localStorage.getItem(key);

export default {
  set,
  get,
  USER_TOKEN,
  USER_INFO,
  USER_RESUMES,
  USER_RESUMES_LEN,
  BASE_INFO,
};
