/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
// import VueLazyload from 'vue-lazyload';
import htmlToPdf from './utils/htmlToPdf';
import App from './App.vue';
import router from './router';
import store from './store';
import getScore from './utils/getScore';

Vue.prototype.$http = axios;

Vue.config.productionTip = true;
Vue.use(ElementUI);
Vue.use(htmlToPdf);
Vue.use(getScore);
// Vue.use(VueLazyload);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
// eslint-disable-next-line vars-on-top, no-use-before-define
var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到

// 统计代码获取
// eslint-disable-next-line func-names
(function () {
  const hm = document.createElement('script');
  hm.src = 'https://hm.baidu.com/hm.js?a318be0408ed04c2e24d8c54ba1ff47f';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
}());
